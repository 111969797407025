import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { MouseEventHandler, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Context {
  showModal: boolean;
  setShowModal: (showModal: boolean) => MouseEventHandler;
  loading: boolean;
  showFooter?: boolean;
}
interface ModalConfig {
  title: string;
  context: any;
  onSubmit?: any;
  customShowModal?: boolean;
  customSetShowModal?: any;
  size?: string;
  fullscreen?: string | true;
}

interface Props {
  modalConfig: ModalConfig;
  children: React.ReactNode;
}

function CrudModuleFactoryModal(props: Props) {
  const {
    title,
    context,
    onSubmit,
    customShowModal,
    customSetShowModal,
    size,
    fullscreen,
  } = props.modalConfig;
  const { children } = props;
  const { showModal, setShowModal, loading, showFooter } =
    useContext<Context>(context);
  const handleClose = () => {
    if (customSetShowModal !== undefined) {
      customSetShowModal(false);
      return;
    }
    setShowModal(false); // We pass the function to close the modal on success
  };
  const onSubmitFunction = () => {
    onSubmit();
  };
  const showModalValue =
    customShowModal !== undefined ? customShowModal : showModal;
  const closeIcon = icon({ name: "xmark", style: "solid" });
  return (
    <>
      <Modal show={showModalValue} onHide={handleClose} className={size} fullscreen={fullscreen}>
        <Modal.Header>
          <Modal.Title as="div">{t(title)}</Modal.Title>
          <a
            href="javascript:;"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <FontAwesomeIcon
              icon={closeIcon}
              className="ms-3 me-2 text-white"
            />
          </a>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {showFooter ||
          (showFooter === undefined && (
            <Modal.Footer>
              <Button variant="outline-dark" onClick={handleClose}>
                {t("close")}
              </Button>
              <Button
                variant="primary"
                disabled={loading}
                onClick={onSubmitFunction}
              >
                {t("save_changes")}
              </Button>
            </Modal.Footer>
          ))}
      </Modal>
    </>
  );
}

export default CrudModuleFactoryModal;
