import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { t } from "i18next";

interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
  icon?: any;
}

interface SidebarProps {
  items: MenuItem[];
}

function createMenuItem(item: MenuItem) {
  if (item.children) {
    return (
      <>
        <NavDropdown
          title={
            <span>
              {item.icon}
              {item.label}
            </span>
          }
          key={item.to}
          id={""}
        >
          {item.children.map(createMenuItem)}
        </NavDropdown>
      </>
    );
  }

  return (
    <Nav.Item key={item.to}>
      <NavLink to={item.to} className="nav-link hover-translate text-black-50">
        {item.icon} {item.label}
      </NavLink>
    </Nav.Item>
  );
}

function Sidebar(props: SidebarProps) {
  const { items } = props;

  return (
    <Nav className="flex-column sidebar d-none d-lg-block p-3">
      <Navbar.Brand href="#home">
        <img src="/logo.png" alt="Dosxtremos" width="120" />
      </Navbar.Brand>
      {items.map(createMenuItem)}
      <Nav.Item className="border-top bottom">
        <NavLink to="/login" className="nav-link hover-translate text-black-50">
          {t("logout")}
        </NavLink>
      </Nav.Item>
    </Nav>
  );
}

export default Sidebar;
