import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import Panel from "@main/panel-components/Panel";
import Login from "@beeldit/user-and-access/auth/pages/Login";
import RequestResetPassword from "@beeldit/user-and-access/auth/pages/RequestResetPassword";
import ResetPassword from "@beeldit/user-and-access/auth/pages/ResetPassword";
import { AuthProvider } from "@beeldit/user-and-access/auth/AuthContext";
import React from "react";

import HeaderDashboardPage from "@bloonde/cms/cms-headers/pages/HeaderDashboardPage";
import { DetailsActionProvider as HeaderDetailsActionProvider } from "@bloonde/cms/cms-headers/actions/details/DetailsActionContext";
import FooterDashboardPage from "@bloonde/cms/cms-footers/pages/FooterDashboardPage";
import { DetailsActionProvider as FooterDetailsActionProvider } from "@bloonde/cms/cms-footers/actions/details/DetailsActionContext";

import { DetailsActionProvider as HouseDetailsActionProvider } from "./houses/actions/details/DetailsActionContext";
import HouseDashboardPage from "@main/houses/pages/HouseDashboardPage";

import { DetailsActionProvider as PageDetailsActionProvider } from "@bloonde/cms/cms-pages/actions/details/DetailsActionContext";
import PageEditionPage from "@bloonde/cms/cms-pages/pages/PageEditionPage";
import { ConfigurationsProvider } from "./contexts/ConfigurationsContext";
import HouseRouterWrapper from "@main/houses/wrappers/HouseRouterWrapper";
import PageRouterWrapper from "@bloonde/cms/cms-pages/wrappers/PageRouterWrapper";

const redirectUrls = {
  admin: "/panel/houses",  
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <ConfigurationsProvider>
          <div className="App">
            <Outlet />
          </div>
        </ConfigurationsProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: "/login",
        element: <Login redirectUrls={redirectUrls}/>,
      },
      {
        path: "/request-reset-password",
        element: <RequestResetPassword />,
      },
      {
        path: "/resetPassword",
        element: <ResetPassword />,
      },
      {
        path: "/",
        index: true,
        element: <Navigate to="/panel/houses" />,
      },
      {
        path: "/panel",
        element: <Panel />,
        children: [
          {
            path: "header",
            element: (
              <HeaderDetailsActionProvider>
                <HeaderDashboardPage />
              </HeaderDetailsActionProvider>
            ),
          },
          {
            path: "footer",
            element: (
              <FooterDetailsActionProvider>
                <FooterDashboardPage />
              </FooterDetailsActionProvider>
            ),
          },
          {
            path: "houses",
            element: (
              <HouseRouterWrapper />
            ),
          },
          {
            path: "houses/:houseId",
            element: (
              <HouseDetailsActionProvider>
                <HouseDashboardPage />
              </HouseDetailsActionProvider>
            ),
          },
          {
            path: "houses",
            element: (
              <PageRouterWrapper config={{ showCreation: false}} filters={{type: 2}} initialUrlFragment="houses" key="houses" />
            ),
          },
          {
            path: "houses/:pageId",
            element: (
              <PageDetailsActionProvider>
                <PageEditionPage />
              </PageDetailsActionProvider>
            ),
          },
          {
            path: "pages",
            element: (
                <PageRouterWrapper config={{ showCreation: false}} filters={{type: 1}} initialUrlFragment="pages" key="pages" />
            ),
          },
          {
            path: "pages/:pageId",
            element: (
              <PageDetailsActionProvider>
                <PageEditionPage />
              </PageDetailsActionProvider>
            ),
          },
          {
            path: "articles",
            element: (
                <PageRouterWrapper config={{ showCreation: false}} filters={{type: 3}} initialUrlFragment="articles" key="articles" />
            ),
          },
          {
            path: "articles/:pageId",
            element: (
              <PageDetailsActionProvider>
                <PageEditionPage />
              </PageDetailsActionProvider>
            ),
          },
        ],
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
