import { User } from "../../users/models/User";

function checkRoles(user: User | null, roles: string[]): boolean {
    if(!user) {
      return false;
    }
    if (roles === undefined) {
      return true;
    }
    return roles.some((role) => user.role === role);
}
export default checkRoles;