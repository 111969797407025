import React, { useEffect } from "react";
import "../scss/BeelditSwitchInput.css"; // Asumiendo que tienes un archivo CSS correspondiente

interface BeelditRadioInputProps {
  id: string;
  required: boolean;
  readonly: boolean;
  disabled: boolean;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  onBlur: (id: string, value: boolean) => void;
  uiSchema: any;
}

const BeelditRadioInput: React.FC<BeelditRadioInputProps> = ({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  uiSchema,
}) => {
  let radio: any = null;
  let radios = null;

  useEffect(() => {
    setTimeout(() => {
      radio = document.getElementById(id);
      if (radio) {
        radio.addEventListener("discheck", dischecked);
      }
      if (value) {
        radio.checked = true;
      }
    }, 2000);
  }, []);

  const handleChange = (value: any) => {
    let newValue = value.target.checked;
    radios = document.getElementsByName(
      uiSchema["ui:options"]["radio-name"]
    );
    if (newValue) {
      for (let i = 0; i < radios.length; i++) {
        if (radios[i].id !== id) {
          radios[i].dispatchEvent(
            new CustomEvent("discheck", {
              detail: { id: radios[i].id },
            })
          );
        }
      }
    }
    onChange(newValue);
  };

  const dischecked = (event: any) => {
    if (event.target.checked) {
      setTimeout(() => {
        radio.click();        
      }, 100);
    }
  };

  let radioName = uiSchema["ui:options"]["radio-name"];

  return (
    <div className="d-flex align-items-center">
        {label}: 
        <input
          className="ms-2 custom-checkbox"
          type="checkbox"
          name={radioName}
          id={id}
          required={required}
          readOnly={readonly}
          disabled={disabled}
          onChange={handleChange}
        ></input>
      </div>
  );
};

export default BeelditRadioInput;
