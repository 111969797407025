import { t } from "i18next";
import moment from "moment";

function HouseApiInfo(props: any) {
  const { pageableData } = props;

  const getFeatureValue = (value: any) => {
    if (value === 1 || value === "true") {
      return t("yes");
    } else if (value === 0 || value === "false") {
      return t("no");
    } else {
      return value;
    }
  }

  return (
    <div>
      {pageableData && (
        <>
          <div className="row">
            <div className="col-6">
              <div>
                <span className="fw-bold">{t("name")}</span> :{" "}
                {pageableData.name}
              </div>
              <div>
                <span className="fw-bold">{t("min_price")}</span> :{" "}
                {pageableData.max_price ? pageableData.max_price.toFixed(2) : '-'}
              </div>
              <div>
                <span className="fw-bold">{t("max_price")}</span> :{" "}
                {pageableData.min_price ? pageableData.min_price.toFixed(2) : '-'}
              </div>
              <div>
                <span className="fw-bold">{t("registration_date")}</span> :{" "}
                {pageableData.registration_date ? moment(pageableData.registration_date, "YYYY-MM-DD").format("DD/MM/YYYY") : '-'}
              </div>
              <div>
                <span className="fw-bold">{t("modified_on")}</span> :{" "}
                {pageableData.modified_on ? moment(pageableData.modified_on, "YYYY-MM-DD").format("DD/MM/YYYY") : '-'}
              </div>
            </div>
            <div className="col-6">
              <div className="fw-bold">{t("features")}:</div>
              <ul>
              {pageableData.features.map((feature: any) => {
                return <li><span className="fw-bold">{t(feature.text['en'])} :</span> {
                  getFeatureValue(feature.pivot.value)
                }</li>;
              })}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default HouseApiInfo;
