import { ObjectFieldTemplateProps } from "@rjsf/utils";

function CustomFormTemplate(props: ObjectFieldTemplateProps) {

  let colsChemas = props.uiSchema?.colsSchema;

  return (
    <>
      {colsChemas.map((row: any, index: any) => (
        <div className="row" key={index}>
          {row.map((element: any, index: any) => (
            <div className={element.cols} key={index}>
              <div className="property-wrapper">
                {getPropertyByName(element.name, props)?.content}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );

  function getPropertyByName(name: string, props: ObjectFieldTemplateProps) {
    let element = props.properties.find((element: any) => element.name === name);
    // console.log(element);
    return element;
  }
}


export default CustomFormTemplate;
