import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import widgets from "@beeldit/core/widgets/widgets";
import TemplateSelector from '@bloonde/cms/cms-templates/components/TemplateSelector';
import {HEADER_TYPE} from '@bloonde/cms/cms-templates/consts/TemplateConsts';

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function HeaderForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const translations = {
    template_id: t('template'),
    logo: t('logo'),
  };

  let schema: RJSFSchema = {
    type: "object",
    properties: {
      logo: {
        type: "array",
        title: translations.logo,
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        }
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    template_id: {
      "ui:widget": "template-selector",
      "ui:options": {
        filters: {
          type: HEADER_TYPE,
        }
      }
    },
    logo: {
      "ui:widget": "file-input",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "template-selector": TemplateSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default HeaderForm;
