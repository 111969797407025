import { useState, useEffect } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import index from "@beeldit/core/services";
import { useTranslation } from "react-i18next";

interface Option {
  label: string;
  value: number | string;
}

function CustomSelectWidget(props: any) {
  const { id, value, required, disabled, readonly, onChange, configPath } =
    props;
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<Option>>(
    []
  );
  const [options, setOptions] = useState<Option[]>([]);
  const [elementsData, setElementsData] = useState({ elements: [] });
  const { t } = useTranslation();

  const errorHandler = useErrorHandler();

  useEffect(() => {
    index(`types/${configPath}`, "")
      .then((response: any) => {
        setElementsData(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, null);
      });
  }, []);

  useEffect(() => {
    const options = elementsData.elements.map(
      (element: { id: any; value: any }) => {
        return { label: t(element.value.toString()), value: element.id };
      }
    );
    setOptions(options);
    const currentSelectedOptions = options.filter((option: any) => {
      return value && value.includes(option.value);
    });
    setSelectedOptions(currentSelectedOptions);
  }, [elementsData]);

  const handleOnChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    setSelectedOptions(newValue);
    const model = newValue.map((option) => {
      return option.value;
    });
    onChange(model);
  };

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "#E7F5F2",
      backgroundColor: "#E7F5F2",
      borderRadius: "0.25rem",
    }),
  };

  return (
    <Select
      id={id}
      isMulti
      options={options}
      value={selectedOptions}
      required={required}
      isDisabled={disabled || readonly}
      onChange={handleOnChange}
      styles={selectStyles}
    />
  );
}

function BeelditTypeMultiSelector(props: any) {
  const { id, options, value, required, disabled, readonly, onChange } = props;
  return (
    <CustomSelectWidget
      id={id}
      options={options.enumOptions}
      value={value}
      required={required}
      disabled={disabled}
      readonly={readonly}
      onChange={onChange}
      configPath={options.configPath}
    />
  );
}

export default BeelditTypeMultiSelector;
