import React, { useContext, useEffect, useState } from "react";
import login from "../services/login";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { addToken, getApi } from "@beeldit/core/helpers/api";
import AuthInterceptor from "../interceptors/AuthInterceptor";
import { t } from "i18next";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import {
  showErrorMessage,
  showSuccessMessage,
} from "@beeldit/core/helpers/messagesHelper";

interface Prop {
  redirectUrls?: any;
  redirectFunction?: any;
}

const Login = (props: Prop) => {
  const { redirectUrls, redirectFunction } = props;
  const logoName = process.env.REACT_APP_LOGO_NAME;
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setToken, setUser } = useContext(AuthContext);

  // Obtenemos los query params message y messageType de la url

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    const messageType = urlParams.get("messageType");
    if (message && messageType) {
      if (messageType === "error") {
        showErrorMessage(message);
      } else {
        showSuccessMessage(message);
      }
    }
    // Borramos los dos parametros para que no se vuelva a mostrar al refrescacr
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    getApi().defaults.headers.common["tenantId"] = null;
    getApi().defaults.headers.common["commerceId"] = null;
  }, []);

  const navigate = useNavigate();

  const onSuccess = (data: any) => {
    const token = data.token;
    const user = data.user;
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    setToken(JSON.stringify(token));
    setUser(user);
    AuthInterceptor(() => token);
    addToken(token);
    let url = "/panel";
    if (redirectFunction) {
      url = redirectFunction(user);
    } else if (redirectUrls) {
      let role = data.user.role;
      if (redirectUrls && redirectUrls[role]) {
        url = redirectUrls[role];
      }
    }

    navigate(url);
  };

  const errorHandler = useErrorHandler();

  const handleLogin = async (e: any) => {
    let requestResetPassword = {};
    if (emailOrUsername.includes("@")) {
      requestResetPassword = {
        email: emailOrUsername,
        password: password,
      };
    } else {
      requestResetPassword = {
        username: emailOrUsername,
        password: password,
      };
    }
    login(requestResetPassword, onSuccess, errorHandler);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin(event);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + backgroundSrc + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="d-flex flex-column align-items-center justify-content-center auth-panel"
        style={{ minHeight: "100vh" }}
      >
        <div className="card">
          <div className="card-body">
            <div className="card-logo">
              <img src={logoName} alt="App Icon" />
            </div>
            <h5 className="card-title text-center mb-4">{t("sign_in")}</h5>
            <form>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  {t("email_or_user")}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="emailOrUsername"
                  value={emailOrUsername}
                  placeholder={t("email_or_user_placeholder")}
                  onChange={(e) => setEmailOrUsername(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  {t("password")}:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  placeholder={t("password_placeholder")}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <button
                onClick={handleLogin}
                type="button"
                className="btn btn-primary w-100"
              >
                {t("sign_in")}
              </button>
              <a href="/request-reset-password" className="btn btn-link w-100">
                {t("forgot_password")}
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
