import React from "react";

import { RemoveActionProvider } from "@main/houses/actions/remove/RemoveActionContext";
import { CreateActionProvider } from "@main/houses/actions/create/CreateActionContext";
import { EditActionProvider } from "@main/houses/actions/edit/EditActionContext";
import { ListActionProvider } from "@main/houses/actions/list/ListActionContext";
import HousesPage from "@main/houses/pages/HousesPage";


export default function HouseRouterWrapper() {

  return (
      <ListActionProvider>
          <RemoveActionProvider>
              <CreateActionProvider>
                  <EditActionProvider>
                      <HousesPage />
                  </EditActionProvider>
              </CreateActionProvider>
          </RemoveActionProvider>
      </ListActionProvider>
  );
};
