import { RJSFSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import TagMultiSelector from "@bloonde/cms/cms-tags/components/TagsMultiSelector";
import { ConfigurationsContext } from "@main/contexts/ConfigurationsContext";
import PageFormTemplate from "./PageFormTemplate";
import SchemaHelper from "@bloonde/cms/helpers/SchemaHelper";
import PageFormArrayTemplate from "./PageFormArrayTemplate";
import projectWidgets from "@main/projectWidgets";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
  formFilterValues?: any;
}

interface Context {
  element: any;
  schema: any;
  schemaUI: any;
  setElement: (element: any) => any;
  setSchema: (schema: any) => any;
  setSchemaUI: (schema: any) => any;
  selectedLang: string;
  setSelectedLang: (lang: string) => any;
  backendFormErrors: any;
}

function PageForm(props: Prop) {
  const { t } = useTranslation();
  const { formRef, onSubmit, context } = props.formConfig;

  const {
    element,
    schema,
    schemaUI,
    setElement,
    setSchema,
    setSchemaUI,
    selectedLang,
    setSelectedLang,
    backendFormErrors,
  } = useContext<Context>(context);

  const { langs, schemas } = useContext<any>(ConfigurationsContext);

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const customWidgets = {
    ...widgets,
    ...projectWidgets,
    ...{ "tag-multi-select": TagMultiSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    if (schema) {
      setFinalSchema(processSchemaFunction(schema, element));
    }
  }, [element]);

  useEffect(() => {
    if (selectedLang && element && schemas && schemas[element.schema_slug]) {
      setSchema(
        SchemaHelper.filterByLanguage(
          schemas[element.schema_slug].schema,
          selectedLang
        )
      );
      setSchemaUI(schemas[element.schema_slug].ui);
      if (element.lang !== selectedLang) {
        const mutedElem = { ...element, lang: selectedLang };
        setElement(mutedElem);
      }
      // console.log('element', element)
      // TODO no parece que funcione al cargar la vista pero si al cambiar de idioma
    }
  }, [selectedLang, element]);

  if (!schema || !schemaUI) {
    return null;
  }

  const formTemplate = PageFormTemplate;
  const arrayTemplate = PageFormArrayTemplate;

  return (
    <>
      <div className="float-end">
        {
          <ButtonGroup>
            {langs.map((lang: string, index: number) => (
              <ToggleButton
                key={index}
                id={`radio-${index}`}
                type="radio"
                variant="outline-primary"
                name="radio"
                value={lang}
                checked={selectedLang === lang}
                onChange={(e) => setSelectedLang(e.currentTarget.value)}
              >
                {t(lang)}
              </ToggleButton>
            ))}
          </ButtonGroup>
        }
      </div>
      <JSONSchemaForm
        className="mt-5"
        noHtml5Validate
        templates={{
          ObjectFieldTemplate: formTemplate,
          ArrayFieldTemplate: arrayTemplate,
        }}
        showErrorList={false}
        ref={formRef}
        schema={finalSchema}
        uiSchema={schemaUI}
        widgets={customWidgets}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={onSubmit}
        onError={log("errors")}
        extraErrors={backendFormErrors}
      />
    </>
  );
}

export default PageForm;
